import { gql } from 'apollo-angular';

const LoginAuth = gql`
  mutation LoginAuth($loginAuthInput: LoginAuthInput!) {
    loginAuth(loginAuthInput: $loginAuthInput) {
      token
      id_permisos
      user {
        id
        id_perfil
        nombre
        email
        company {
          nombre
        }
        profile {
          nombre
        }
      }
    }
  }
`;

const revalidateAuth = gql`
  query CheckTokenAuth {
    checkTokenAuth {
      token
      id_permisos
      user {
        id
        id_perfil
        nombre
        email
        company {
          nombre
        }
        profile {
          nombre
        }
      }
    }
  }
`;

export { LoginAuth
       , revalidateAuth
    };
