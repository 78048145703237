export const environment = {
  siteUrl: 'https://qa.moon.buhologistics.com',
  // baseUrl: 'http://localhost:4000/graphql',
  baseUrl: 'https://qa.api.moon.buhologistics.com/graphql',
  baseUrlPostalCodes: 'https://api.graphql.buhologistics.com/graphql',
  permisos: {
    per_ord: { id: 1, active: false },
    per_ord_add: { id: 2, active: false },
    per_ord_rec: { id: 3, active: false },
    per_ord_print: { id: 4, active: false },
    per_ord_downl: { id: 5, active: false },
    per_ord_emp: { id: 6, active: false },
    per_ord_emp_gen_label: { id: 7, active: false },
    per_ord_emp_can: { id: 8, active: false },
    per_ord_emp_mod_des: { id: 9, active: false },
    per_pro: { id: 10, active: false },
    per_pro_add: { id: 11, active: false },
    per_pro_mod: { id: 12, active: false },
    per_pro_act: { id: 13, active: false },
    per_pro_del: { id: 14, active: false },
    per_pro_downl: { id: 15, active: false },
    per_pack: { id: 16, active: false },
    per_pack_add: { id: 17, active: false },
    per_pack_mod: { id: 18, active: false },
    per_pack_act: { id: 19, active: false },
    per_pack_del: { id: 20, active: false },
    per_pack_downl: { id: 21, active: false },
    per_rec: { id: 22, active: false },
    per_rec_add: { id: 23, active: false },
    per_rec_close: { id: 24, active: false },
    per_rec_can: { id: 25, active: false },
    per_rec_downl: { id: 40, active: false },
    per_usr: { id: 26, active: false },
    per_usr_add: { id: 27, active: false },
    per_usr_mod: { id: 28, active: false },
    per_usr_act: { id: 29, active: false },
    per_usr_del: { id: 41, active: false },
    per_usr_grp: { id: 30, active: false },
    per_usr_grp_add: { id: 31, active: false },
    per_usr_grp_mod: { id: 32, active: false },
    per_usr_grp_del: { id: 33, active: false },
    per_usr_grp_downl: { id: 34, active: false },
    per_per: { id: 35, active: false },
    per_conf: { id: 39, active: false },
    per_sla: { id: 36, active: false },
    per_sh_met: { id: 37, active: false },
    per_dash: { id: 38, active: false }
  }
}
