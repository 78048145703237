import { Routes } from '@angular/router';
import { isNotAuthenticatedGuard } from '@guards/is-not-authenticated.guard';
import { isAuthenticatedGuard } from '@guards/is-authenticated.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./auth/login/login.component'),
    canActivate: [isNotAuthenticatedGuard],
  },
  {
    path: 'layout',
    canActivate: [isAuthenticatedGuard],
    loadComponent: () => import('./layouts/home/home.component'),
    children: [
      {
        path: 'home',
        title: 'Home',
        loadComponent: () => import('./layouts/home/layout/layout.component'),
      },
      {
        path: 'orders',
        title: 'Órdenes',
        loadComponent: () => import('./layouts/orders/orders-list/orders-list.component').then(m => m.OrdersListComponent),
      },
      {
        path: 'addorder',
        title: 'Nueva Orden',
        loadComponent: () => import('./layouts/orders/create-order/create-order.component').then(m => m.CreateOrderComponent),
      },
      {
        path: 'receptions',
        title: 'Recepciones',
        loadComponent: () => import('./layouts/receptions/receptions-list/receptions-list.component').then(m => m.ReceptionsListComponent),
      },
      {
        path: 'reception/:id',
        title: 'Recepcion',
        loadComponent: () => import('./layouts/receptions/reception-item/reception-item.component').then(m => m.ReceptionItemComponent),
      },
      {
        path: 'configuration',
        title: 'Configuracion',

          children: [
            {
              path:'',
              loadComponent: () =>
                import('./layouts/configuration/configuration-list/configuration-list.component').then(
                  (m) => m.ConfigurationListComponent
                ),
            },

          {
            path: 'shipping-method',
            title: 'Métodos de Envío',
            loadComponent: () =>
              import('./layouts/configuration/shipping-method/shipping-method.component').then(
                (m) => m.ShippingMethodComponent
              ),
          },
        ],
      },
      {
        path: 'packing/:id',
        title: 'Empacar',
        loadComponent: () => import('./layouts/packing/packing-item/packing-item.component').then(m => m.PackingComponent),
      },
      {
        path: 'packing',
        title: 'Empacar',
        loadComponent: () => import('./layouts/packing/packing-list/packing-list.component').then(m => m.PackingListComponent),
      },
      {
        path: 'inventory',
        title: 'Inventario',
        children: [
          {
            path: 'empaques',
            title: 'Empaques',
            loadComponent: () => import('./layouts/inventory/in-packing/in-packing.component').then(m => m.PackagingComponent),
          },
          {
            path: 'productos',
            title: 'Productos',
            loadComponent: () => import('./layouts/inventory/products/products.component').then(m => m.ProductsComponent),
          }
        ]
      },
      {
        path: 'admin-users',
        title: 'Administración de usuarios',
        children: [
          {
            path: 'users',
            title: 'Usuarios',
            loadComponent: () => import('./layouts/users/users-list/users-list.component').then(m => m.UsersListComponent),
          },
          {
            path: 'group-users',
            title: 'Grupo de usuarios',
            loadComponent: () => import('./layouts/users/grupo-usuarios/grupo-usuarios.component').then(m => m.GrupoUsuariosComponent),
          },
          {
            path: 'access-users',
            title: 'Permisos',
            loadComponent: () => import('./layouts/users/permisos/permisos.component').then(m => m.PermisosComponent),
          },
        ]
      }


      // {
      //   path:'**', redirectTo: 'home', pathMatch: 'full',
      // }
    ]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];
