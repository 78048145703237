import { computed, inject, Injectable, signal } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { AuthStatus, User } from '../interfaces';
import { Apollo } from 'apollo-angular';
import { LoginAuth, revalidateAuth } from '../gql/gqlLogin';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private router = inject(Router);
  private _currentUser = signal<User|null>(null);
  private _authStatus =  signal<AuthStatus>(AuthStatus.checking);

  public currentUser = computed(() => this._currentUser());
  public authStatus  = computed(() => this._authStatus());

  constructor(private readonly apollo : Apollo) {
    this.checkAuthStatus().subscribe();
  }

  private setAuthentication(user: User, token: string): boolean {
    const permisos:any = this.getPermisions(user);
    user.permisos = this.getPermisions(user);
    this._currentUser.set(user);
    this._authStatus.set( AuthStatus.authenticated );
    localStorage.setItem('token', token);

    return true;
  }

  private getPermisions(user: User) {
    const permisos:any = JSON.parse(JSON.stringify(environment.permisos));
    user.id_permisos.map((e:any) => {
      for (const key in permisos) {
        if (Object.prototype.hasOwnProperty.call(permisos, key)) {
          const p = permisos[key];
          if(p.id==e) {
            permisos[key].active = true;
          }
        }
      }
    })
    return permisos;
  }

  login( email: string, pass: string ): Observable<boolean>{
    return this.apollo.mutate({
      mutation: LoginAuth,
      variables : {
        loginAuthInput: {
          pass: pass,
          email: email
        }
      }
    })
    .pipe(
      map(({ data } : any) => {
        data.loginAuth.user.id_permisos = data.loginAuth.id_permisos;
        this.setAuthentication( data.loginAuth.user, data.loginAuth.token ) 
      }) ,
      map(() => true),
      catchError(err => throwError(() => err)));
  }

  checkAuthStatus():Observable<boolean> {
    const token = localStorage.getItem('token');

    if ( !token ) {
      this.logout();
      return of(false);
    }

    return this.apollo.query({
      query: revalidateAuth
    })
    .pipe(
      map(({ data } : any) => {
        const us = Object.assign( JSON.parse(JSON.stringify(data.checkTokenAuth.user)), { id_permisos: data.checkTokenAuth.id_permisos });
        this.setAuthentication( us, data.checkTokenAuth.token )
      }),
      map(() => true),
      catchError(err => throwError(() => err)));
  }

  logout(){
    this.apollo.client.resetStore();
    localStorage.clear();
    this._currentUser.set(null);
    this._authStatus.set( AuthStatus.notAuthenticated );
    this.router.navigate(['/login'], { replaceUrl : true});
  }
}
